import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  color: string;
  isSlanted?: boolean;
  shadow?: boolean;
};

export const FullBackground: React.FC<Props> = ({
  children,
  color,
  isSlanted = true,
  shadow = true,
}) => {
  return (
    <div
      className="relative "
      style={{ marginLeft: '-50vw', marginRight: '-50vw' }}
    >
      {isSlanted && (
        <div
          className={`absolute inset-0  ${
            shadow ? 'shadow-lg ' : ' '
          } transform -skew-y-6 sm:-skew-y-6 sm:-rotate-6 sm:rounded-3xl ${color}`}
        />
      )}
      {!isSlanted && <div className={`absolute inset-0  ${color}`} />}

      {children}
    </div>
  );
};
