import React from 'react';
import { FullBackground } from '../../../components/FullBackground';
import { FSRLanding } from './FSRLanding';

type Props = {
  className?: string;
};

const UseCase: React.FC<Props> = ({ className }) => {
  return (
    <FullBackground color={'bg-gray-100'} isSlanted={false}>
      <FSRLanding
        className={''}
        style={{ marginLeft: '50vw', marginRight: '50vw' }}
      />
    </FullBackground>
  );
};

export default UseCase;
