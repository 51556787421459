import React from 'react';
import { getImageSrc } from '../../../util';

type Props = {
  className?: string;
};

export const ProductManagement: React.FC<Props> = ({ className }) => {
  return (
    <>
      <div className={`py-16 lg:py-24 ${className}`} id={'management'}>
        <div className="relative max-w-xl max-w-7xl">
          <div className="max-w-3xl mx-auto text-left">
            <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
              Management
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              We're management material.
            </p>
          </div>

          <div className="relative mt-2 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-xl text-primary-600 tracking-tight sm:text-2xl">
                Tab Management
              </h3>
              <dl className="mt-4 space-y-10">
                <div className="flex">
                  <div className="">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Simple Interface for Guests
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      When guests place their first order, they start a tab.
                      Guests add to their tab by ordering more during the dining
                      experience. If a guests forgets to close their tab when
                      done, we do so automatically.
                    </dd>
                  </div>
                </div>
                <div className="flex">
                  <div className="">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Consolidated Tickets for Staff
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Rather than create a ticket for every single order placed
                      from a table, we consolidate all table orders under one
                      ticket. Tickets are updated automatically as guests place
                      additional orders.
                    </dd>
                  </div>
                </div>
              </dl>
            </div>

            <div className="mt-10 relative lg:mt-0" aria-hidden="true">
              <div className="relative py-3 lg:w-full mx-auto">
                {/*<div className="absolute px-4 py-10 bg-white shadow-lg rounded-2xl sm:rounded-3xl sm:p-20 bg-primary-600" />*/}
                <div className={' lg:w-4/5 xl:w-3/5 md:w-3/5 w-4/5 mx-auto '}>
                  <picture>
                    <source
                      srcSet={getImageSrc('home_tab_management_1.webp')}
                      type={'image/webp'}
                    />
                    <source
                      srcSet={getImageSrc('home_tab_management_1.png')}
                      type={'image/png'}
                    />
                    <img
                      src={getImageSrc('home_tab_management_1.webp')}
                      alt={'qr codes'}
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative mt-12 sm:mt-16 lg:mt-32"
            id={'menu-management'}
          >
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2 lg:ml-10">
                <h3 className="text-xl  text-primary-600 tracking-tight sm:text-2xl">
                  Operational Management
                </h3>

                <dl className="mt-4 space-y-10">
                  <div className="flex">
                    <div className="">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        Digital Menu Dashboard
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        Your dashboard comes with a complete list of menu
                        management capabilities, allowing menu changes to be
                        made in real-time so guests can see your most updated
                        offerings.
                      </dd>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        QR Code Library
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        You can automatically generate, download, and print QR
                        codes directly from your TableTab account. Guests who
                        scan our QR codes go directly to your digital menu
                        without having to create an account.
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>

              <div
                className=" mt-14 relative lg:mt-0 lg:col-start-1"
                style={{ marginLeft: '-50vw', marginRight: '-50vw' }}
              >
                <div className=" flex flex-col ">
                  git
                  <div
                    className=" -ml-32 lg:inline-block hidden"
                    style={{ marginLeft: '40vw', marginRight: '50vw' }}
                  >
                    <div className=" align-middle inline-block w-full ">
                      <div className="shadow overflow-hidden rounded-lg md:shadow-2xl shadow-lg w-full mx-auto">
                        <picture>
                          <source
                            srcSet={getImageSrc('home_dashboard.webp')}
                            type={'image/webp'}
                          />
                          <source
                            srcSet={getImageSrc('home_dashboard.jpg')}
                            type={'image/jpeg'}
                          />
                          <img
                            className={' '}
                            src={getImageSrc('home_dashboard.webp')}
                            alt={'menu management system'}
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                  <div
                    className="  lg:hidden block"
                    style={{ marginLeft: '50vw', marginRight: '50vw' }}
                  >
                    <div className=" align-middle inline-block w-full ">
                      <div className="shadow overflow-hidden rounded-lg md:shadow-2xl shadow-lg w-full mx-auto">
                        <picture>
                          <source
                            srcSet={getImageSrc('home_dashboard.webp')}
                            type={'image/webp'}
                          />
                          <source
                            srcSet={getImageSrc('home_dashboard.jpg')}
                            type={'image/jpeg'}
                          />
                          <img
                            className={' '}
                            src={getImageSrc('home_dashboard.webp')}
                            alt={'menu management system'}
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
