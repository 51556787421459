import React, { useRef } from 'react';
import { getImageSrc } from '../../util';
import { useSpring, animated } from 'react-spring';

export const UseCaseLandingTestimonial: React.FC = () => {
  const ref = useRef(null);

  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 60 },
  }));
  const calc = (x, y) => [
    -(
      y -
      ref.current.getBoundingClientRect()['top'] -
      ref.current.clientHeight / 2
    ) / 40,
    (x -
      ref.current.getBoundingClientRect()['left'] -
      ref.current.clientWidth / 2) /
      20,
    1.05,
  ];
  const trans = (x, y, s) => `perspective(600px) scale(${s})`;
  return (
    <animated.section
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      // @ts-ignore
      style={{ transform: props.xys.interpolate(trans) }}
      className="py-12 mt-60 lg:mt-40 bg-primary-600 overflow-hidden md:py-20 lg:py-24 rounded-lg z-10 mt-32 mb-10  white_shadow z-20 mx-10 lg:mx-10"
      ref={ref}
    >
      <div className="relative max-w-5xl mx-auto px-4 sm:px-4 lg:px-8">
        <div className="relative">
          <blockquote className="mt-4 md:mt-10">
            <div className="max-w-3xl mx-auto text-center text-lg md:text-xl lg:text-2xl leading-9 font-medium text-white">
              <p>
                &ldquo;TableTab allows us to serve guests safely mainly by
                cutting out payment transaction.<span>&#160;</span>Guests can
                also see the photos on menu which is hard to do on paper
                everyday.
                <span>&#160;</span>Cherry on top is that the team has impeccable
                service.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <picture>
                    <source
                      srcSet={getImageSrc('yuka.webp')}
                      type={'image/webp'}
                    />
                    <source
                      srcSet={getImageSrc('yuka.jpg')}
                      type={'image/jpeg'}
                    />
                    <img
                      className=" lazyload mx-auto h-10 w-10 rounded-full"
                      width={'10'}
                      height={'10'}
                      data-src={getImageSrc('yuka.webp')}
                      alt=""
                    />
                  </picture>
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-white">
                    Yuka Ioroi
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-white">
                    Owner, Cassava
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </animated.section>
  );
};
