import React from 'react';
import { FullBackground } from '../../../components/FullBackground';

type Props = { className?: string };

const ProductPricing: React.FC<Props> = ({ className }) => {
  return (
    <>
      <FullBackground color={`bg-primary-600 `} isSlanted={false}>
        <div
          className={`relative bg-primary-600  ${className}`}
          id={'pricing'}
          style={{ marginRight: '50vw', marginLeft: '50vw' }}
        >
          <div className="bg-gray-100 rounded-xl ">
            <div className="relative">
              <div className="absolute inset-0 h-1/2 bg-gray-100 rounded-xl" />
              <div className="relative max-w-7xl mx-auto">
                <div className="max-w-lg mx-auto rounded-lg shadow-xl overflow-hidden lg:max-w-none lg:flex">
                  <div className="flex-1 bg-white px-6 py-8 lg:p-12 lg:max-w-3/4">
                    <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
                      Pricing & Next Steps
                    </h2>
                    <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                      No Commissions. No Commitments.
                    </h3>
                    <p className="mt-6 text-base text-gray-500">
                      A complete digital dine-in operation with free mobile
                      menus and free QR code hosting and management.
                    </p>
                    {/* <p className="mt-3 text-base text-gray-500">
                      <b>February Offer:</b> Get started now for a 30 day free
                      trial.
                    </p> */}
                    <div className="mt-8">
                      <div className="flex items-center">
                        <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-primary-600">
                          On-boarding Process
                        </h4>
                        <div className="flex-1 border-t-2 border-gray-200" />
                      </div>
                      <nav aria-label="Progress">
                        <ol className="mt-10 border border-gray-300 rounded-md divide-gray-300 md:flex cursor-default">
                          <li className="relative md:flex-1 md:flex cursor-default">
                            <span className="group flex items-center w-full cursor-default">
                              <span className="px-6 py-4 flex items-center text-sm font-medium">
                                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full ">
                                  <svg
                                    className="w-6 h-6 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                                <span className={'flex-col flex'}>
                                  <span className="ml-4 text-sm font-medium text-gray-900">
                                    Create account
                                  </span>
                                  <span className="ml-4 mt-1 text-xs font-medium text-gray-900">
                                    &#60;30 seconds
                                  </span>
                                </span>
                              </span>
                            </span>

                            <div
                              className="hidden md:block absolute top-0 right-0 h-full w-5"
                              aria-hidden="true"
                            >
                              <svg
                                className="h-full w-full text-gray-300"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none"
                              >
                                <path
                                  d="M0 -2L20 40L0 82"
                                  vectorEffect="non-scaling-stroke"
                                  stroke="currentcolor"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </li>

                          <li className="relative md:flex-1 md:flex cursor-default">
                            <span className="group flex items-center w-full cursor-default">
                              <span className="px-6 py-4 flex items-center text-sm font-medium">
                                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full ">
                                  <svg
                                    className="w-6 h-6 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                                <span className={'flex-col flex'}>
                                  <span className="ml-4 text-sm font-medium text-gray-900">
                                    Upload menu(s)
                                  </span>
                                  <span className="ml-4 mt-1 text-xs font-medium text-gray-900">
                                    Import or build
                                  </span>
                                </span>
                              </span>
                            </span>

                            <div
                              className="hidden md:block absolute top-0 right-0 h-full w-5"
                              aria-hidden="true"
                            >
                              <svg
                                className="h-full w-full text-gray-300"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none"
                              >
                                <path
                                  d="M0 -2L20 40L0 82"
                                  vectorEffect="non-scaling-stroke"
                                  stroke="currentcolor"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </li>

                          <li className="relative md:flex-1 md:flex cursor-default">
                            <span className="px-6 py-4 flex items-center text-sm font-medium cursor-default">
                              <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full ">
                                <svg
                                  className="w-6 h-6 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                              <span className={'flex-col flex'}>
                                <span className="ml-4 text-sm font-medium text-gray-900">
                                  Setup direct deposit
                                </span>
                                <span className="ml-4 mt-1 text-xs font-medium text-gray-900">
                                  &#60;5 minutes
                                </span>
                              </span>
                            </span>

                            <div
                              className="hidden md:block absolute top-0 right-0 h-full w-5"
                              aria-hidden="true"
                            >
                              <svg
                                className="h-full w-full text-gray-300"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none"
                              >
                                <path
                                  d="M0 -2L20 40L0 82"
                                  vectorEffect="non-scaling-stroke"
                                  stroke="currentcolor"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </li>

                          <li className="relative md:flex-1 md:flex cursor-default">
                            <span className="group flex items-center cursor-default">
                              <span className="px-6 py-4 flex items-center text-sm font-medium">
                                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full ">
                                  <svg
                                    className="w-6 h-6 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                                <span className={'flex-col flex'}>
                                  <span className="ml-4 text-sm font-medium text-gray-900">
                                    Print QR Codes
                                  </span>
                                  <span className="ml-4 mt-1 text-xs font-medium text-gray-900">
                                    And go live!
                                  </span>
                                </span>
                              </span>
                            </span>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                  <div className="py-4 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:min-w-1/4">
                    {/*<p className="text-lg leading-6 font-medium text-gray-900">*/}
                    {/*  As low as*/}
                    {/*</p>*/}
                    {/*<div className="mt-2 flex items-center justify-center text-5xl font-extrabold text-primary-600">*/}
                    {/*  <span>$0.29</span>*/}
                    {/*  <span className="ml-3 text-xl font-medium text-gray-600">*/}
                    {/*    / cover*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                    {/*<p className={'mt-2 text-md font-light text-gray-600'}>*/}
                    {/*  Credit card processing fee: 2.9% + 30&cent;*/}
                    {/*</p>*/}
                    <div className="mt-6 mx-auto">
                      <span className="inline-flex items-center text-sm font-bold text-gray-900 space-x-1">
                        Start the Process
                      </span>
                    </div>
                    <div className="mt-2 lg:w-4/5 w-full mx-auto">
                      <div className="rounded-full shadow ">
                        <a
                          href="https://portal.tabletab.io/signup?ref=product-pricing"
                          target="blank"
                          id={'product_pricing_cta'}
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-lg capitalize  font-bold rounded-full text-white bg-gray-700 hover:bg-gray-800 hover:text-white"
                        >
                          Create Account
                        </a>
                      </div>
                      <div className="mt-10 mx-auto">
                        <span className="inline-flex text-sm font-medium text-gray-900 space-x-1 hover:text-primary-600">
                          <a
                            href="https://meetings.hubspot.com/greg398/30-minute-demo"
                            className="hover:text-primary-600"
                            target="_blank"
                          >
                            Or, book a demo
                          </a>
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            aria-hidden="true"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FullBackground>
    </>
  );
};

export default ProductPricing;
