import React from 'react';
import { getImageSrc } from '../../../util';
import { UseCaseLandingTestimonial } from '../../Testimonials/UseCaseTestimonial';

type Props = {
  className?: string;
  style: React.CSSProperties;
};

export const FSRLanding: React.FC<Props> = ({ className, style }) => {
  return (
    <div
      className={`py-16 overflow-hidden lg:py-24 ${className}`}
      style={style}
    >
      <div className="relative max-w-xl lg:max-w-7xl">
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
              Solution
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              There's no turnover with TableTab.
            </p>
            <div className="text-xl text-gray-500 lg:mx-auto my-4">
              <p>
                Staffing has never been more painful, expensive, and
                unpredictable. TableTab lets your restaurant avoid rehiring and
                maintain a lean operation while improving your quality of
                service and bottom line.
              </p>
            </div>

            {/* <div className="mt-4 flex">
              <div className="inline-flex rounded-md shadow rounded-full">
                <a
                  href="/product#workflow"
                  className="inline-flex items-center justify-center px-5 rounded-full hover:text-white py-1 border border-transparent text-base font-medium rounded-full text-white bg-primary-600 hover:bg-primary-500"
                >
                  View workflow
                </a>
              </div>
            </div> */}

            <dl className="mt-10 space-y-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-600 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Save on Labor
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    TableTab helps your servers cover more tables at once,
                    reducing your need to rehire.
                  </dd>
                </div>
              </div>

              {/* <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-600 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-white">
                    Increase Spending
                  </dt>
                  <dd className="mt-2 text-base text-gray-100">
                    Your guests can finally order that extra drink when they want.
                  </dd>
                </div>
              </div> */}

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-600 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Increase Spending
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Guests open a tab when they place their first order and can
                    add to the tab whenever they want to.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-600 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Stay healthy
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    A contactless ordering system eliminates the spread of germs
                    while still facilitating a service-oriented experience.
                  </dd>
                </div>
              </div>
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <picture className="relative mx-auto rounded-lg w-3/4 lg:w-4/5 md:w-3/4 2xl:w-3/4 my-auto">
              <source
                srcSet={getImageSrc('home_solution.webp')}
                type={'image/webp'}
              />
              <source
                srcSet={getImageSrc('home_solution.jpg')}
                type={'image/jpeg'}
              />
              <img
                className="relative mx-auto rounded-lg w-3/4 lg:w-4/5 md:w-3/4 2xl:w-3/4 my-auto"
                // width="490"
                src={getImageSrc('home_solution.webp')}
                alt=""
              />
            </picture>
          </div>
        </div>

        <UseCaseLandingTestimonial />
      </div>
    </div>
  );
};
