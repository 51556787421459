import React, { useEffect, useRef, useState } from 'react';
import { getImageSrc } from '../../../util';
import { useSpring, animated } from 'react-spring';

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export const BannerVideo: React.FC<Props> = ({ className, style }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setHeight(ref.current.clientHeight);
    setWidth(ref.current.offsetWidth);
  }, [ref]);

  const calc = (x, y) => [
    -(
      y -
      ref.current.getBoundingClientRect()['top'] -
      ref.current.clientHeight / 2
    ) / 40,
    (x -
      ref.current.getBoundingClientRect()['left'] -
      ref.current.clientWidth / 2) /
      20,
    1.05,
  ];
  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 60 },
  }));

  return (
    <div
      className={`flex justify-center mt-10 lg:mt-0 row-start-1 lg:col-start-2 lg:mb-0 ${className}`}
      style={style}
    >
      <animated.div
        className={
          'border-1 object-contain rounded-xl inset-0 bg-white w-5/12 md:w-4/12 lg:w-5/12 h-2/6 shadow-xl transform sm:rotate-6  sm:rounded-3xl'
        }
        onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        // @ts-ignore
        style={{
          // @ts-ignore
          transform: props.xys.interpolate(trans),
          width: '360',
          height: '600',
        }}
      >
        <video
          className={
            ' lazyload border-4 border-white object-contain rounded-xl shadow-xl '
          }
          id={'product_video'}
          autoPlay={true}
          playsInline={true}
          poster={getImageSrc('cassava_menu_v2.webp')}
          loop={true}
          width="360"
          height="600"
          data-src={getImageSrc('home_banner_720.mov')}
          ref={ref}
          muted={true}
        />
      </animated.div>
    </div>
  );
};
