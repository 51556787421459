import React, { useState, useEffect } from 'react';
import { getImageSrc } from '../../../util';

type Props = {
  className?: string;
};

const LandingProduct: React.FC<Props> = ({ className }) => {
  const [index, set] = useState(0);

  useEffect(
    () => void setInterval(() => set(state => (state + 1) % 2), 6000),
    [],
  );

  return (
    <div className={`py-12 bg-gray-100 ${className}`}>
      <div className="max-w-7xl">
        <div className="text-left">
          <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
            Product
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            An all-in-one contactless mobile ordering system.
          </p>
          {/* <p className=""> */}
          <div className="grid grid-cols-1 grid-rows-2 md:grid-cols-1 md:grid-rows-1 gap-6 max-w-2xl text-xl text-gray-500 lg:mx-auto my-4">
            {/*<div className={"row"}>*/}
            <span className={'inline block md:w-3/4 lg:w-2/4'}>
              We bring together everything needed to run a better front of house
              operation that is{' '}
              <a
                id={'dZPO1x'}
                href={'/product?ref=home_product_dZPO1x_inline#safety'}
                className={'text-primary-600 hover:text-primary-700'}
                onClick={e => {
                  // e.preventDefault();
                  // @ts-ignore
                  analytics.track('Safety Clicked', {
                    title: 'safe',
                    action: 'click',
                    location: 'home_product',
                    destination: 'product_safety',
                  });
                  // history.push('/use-cases/fsr#margin-impact?ref=home_product_aoBx9N_inline')
                }}
              >
                safe
              </a>{' '}
              and{' '}
              <a
                id={'aoBx9N'}
                href={
                  '/use-cases/fsr?ref=home_product_aoBx9N_inline#margin-impact'
                }
                className={'text-primary-600 hover:text-primary-700'}
                onClick={e => {
                  // e.preventDefault();
                  // @ts-ignore
                  analytics.track('Impact Clicked', {
                    title: 'profitable',
                    action: 'click',
                    location: 'home_product',
                    destination: 'fsr_impact',
                  });
                  // history.push('/use-cases/fsr#margin-impact?ref=home_product_aoBx9N_inline')
                }}
              >
                profitable
              </a>
              . TableTab's mobile ordering system captures orders with{' '}
              <a
                id={'0cIb4S'}
                href={'/product?ref=home_product_0cIb4S_inline#digital-menus'}
                className={'text-primary-600 hover:text-primary-700'}
                onClick={() => {
                  // e.preventDefault();
                  // @ts-ignore
                  analytics.track('Digital Menu Clicked', {
                    title: 'clickable_menus',
                    action: 'click',
                    location: 'home_product',
                    destination: 'product_digital_menus',
                  });
                  // history.push('/use-cases/fsr#margin-impact?ref=home_product_aoBx9N_inline')
                }}
              >
                clickable menus
              </a>{' '}
              and
              <a
                id={'nEuTvL'}
                href={'/product?ref=home_product_nEuTvL_inline#order-pay'}
                className={'text-primary-600 hover:text-primary-700'}
                onClick={() => {
                  // e.preventDefault();
                  // @ts-ignore
                  analytics.track('Order Pay Clicked', {
                    title: 'contactless_pay',
                    action: 'click',
                    location: 'home_product',
                    destination: 'product_order_pay',
                  });
                  // history.push('/use-cases/fsr#margin-impact?ref=home_product_aoBx9N_inline')
                }}
              >
                {' '}
                contactless pay
              </a>
              .
            </span>
          </div>
          {/* </p> */}
          <div className="mt-4 xl:mt-6 flex">
            <div className="inline-flex rounded-md shadow rounded-full">
              <a
                href="/product/"
                className="inline-flex items-center justify-center px-5 rounded-full hover:text-white py-1 border border-transparent text-base font-medium rounded-full text-white bg-primary-600 hover:bg-primary-500"
              >
                Give me more details
              </a>
            </div>
            <div className="inline-flex rounded-md shadow rounded-full ml-3">
              <a
                href="/contact/"
                className="w-full flex items-center justify-center px-5 py-1 border border-transparent font-medium rounded-full text-primary-700 bg-primary-100 hover:bg-primary-200 md:py-1 text-base hover:text-primary-700"
              >
                Get in touch
              </a>
            </div>
          </div>
        </div>

        <div className="mt-10 lg:-mt-10 lg:-mr-20 md:mt-10 md:-mr-20">
          <dl className="grid grid-cols-2 gap-y-4 justify-items-center md:grid md:grid-cols-10 md:gap-6 lg:gap-10 md:grid-rows-10 grid-rows-min xl:items-center">
            <div className="flex-col my-auto row-start-1 col-start-1 col-span-1 md:col-span-4 md:col-start-7 md:row-start-1 md:row-span-4 justify-center ">
              <div className="w-full md:w-full lg:w-full xl:w-4/5 rounded-2xl shadow-lg md:shadow-2xl xl:-ml-14 border-white md:border-8 border-4">
                <picture>
                  <source
                    srcSet={getImageSrc('landing_tablet_v3.webp')}
                    type={'image/webp'}
                  />
                  <source
                    srcSet={getImageSrc('landing_tablet_v3.jpg')}
                    type={'image/jpeg'}
                  />
                  <img
                    className="lazyload rounded-lg object-cover "
                    width="600"
                    height="400"
                    data-src={getImageSrc('landing_tablet_v3.webp')}
                    alt="table tab kitchen app screenshot"
                  />
                </picture>
              </div>
            </div>

            <div className=" inline-flex items-start md:flex-col  justify-items-center row-start-2 col-start-1 col-span-2 justify-center md:col-span-2 md:row-span-4 md:col-start-5 md:row-start-1  ">
              <div className="w-1/2 mr-2 md:w-full lg:w-full xl:w-4/5 md:mx-auto rounded-2xl xl:ml-0 shadow-lg md:shadow-2xl border-white md:border-8 border-4">
                <picture>
                  <source
                    srcSet={getImageSrc('card_front_dark.webp')}
                    type={'image/webp'}
                  />
                  <source
                    srcSet={getImageSrc('card_front_dark.png')}
                    type={'image/png'}
                  />
                  <img
                    className=" lazyload rounded-lg object-cover "
                    width="360"
                    height="600"
                    data-src={getImageSrc('card_front_dark.webp')}
                    alt="App screenshot"
                  />
                </picture>
              </div>
              <div className="w-1/2 md:mt-6 lg:mt-6 sm:w-1/2 md:w-full mx-auto lg:w-full xl:w-4/5 xl:ml-0  rounded-2xl shadow-lg md:shadow-2xl border-white md:border-8 border-4">
                <picture>
                  <source
                    srcSet={getImageSrc('card_back_dark.webp')}
                    type={'image/webp'}
                  />
                  <source
                    srcSet={getImageSrc('card_back_dark.png')}
                    type={'image/png'}
                  />
                  <img
                    className="lazyload rounded-lg object-cover"
                    style={{ height: 'auto', width: '100%' }}
                    data-src={getImageSrc('card_back_dark.webp')}
                    alt="App screenshot"
                  />
                </picture>
              </div>
            </div>

            <div className="flex-col col-span-1 row-start-1 col-start-2 md:col-span-4 md:col-start-1 md:row-start-2 lg:row-start-2 md:row-span-6 justify-center ">
              <div className=" w-3/4 md:w-2/3 lg:w-2/3 xl:w-3/5 mx-auto xl:mr-0 lg:mr-0 md:mr-0 rounded-xl shadow-lg md:shadow-2xl border-white md:border-8 border-4 ">
                <picture>
                  <source
                    srcSet={getImageSrc('cassava_menu_v2.webp')}
                    type={'image/webp'}
                  />
                  <source
                    srcSet={getImageSrc('cassava_menu_v2.jpg')}
                    type={'image/jpeg'}
                  />
                  <img
                    className="lazyload rounded-lg object-cover "
                    width="500"
                    height="800"
                    data-src={getImageSrc('cassava_menu_v2.webp')}
                    alt="App screenshot"
                  />
                </picture>
              </div>
            </div>

            <div className="flex row-start-3 col-start-1 col-span-2 md:col-span-7 md:col-start-5 md:row-start-5 md:row-span-5 items-end ">
              <div className="shadow-lg md:shadow-2xl border-white md:border-8 border-4 rounded-2xl">
                <picture>
                  <source
                    srcSet={getImageSrc('Dashboard_v2.webp')}
                    type={'image/webp'}
                  />
                  <source
                    srcSet={getImageSrc('Dashboard_v2.jpg')}
                    type={'image/jpeg'}
                  />
                  <img
                    className="lazyload rounded-lg object-fit"
                    width="900"
                    height="400"
                    data-src={getImageSrc('Dashboard_v2.webp')}
                    alt="App screenshot"
                  />
                </picture>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default LandingProduct;
