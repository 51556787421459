import { APG } from '../env';
import axios from 'axios';
import { MarketingAPIQueuePushRequest } from '../interfaces/marketing';
import { getAuthHeaders } from './utils';

export const marketingAPI = {
  async pushEmailToQueue(request: MarketingAPIQueuePushRequest) {
    return axios.post(`${APG}/emails/marketing/enqueue`, request, {
      headers: getAuthHeaders(),
    });
  },
};
