import React from 'react';
import { getImageSrc } from '../util';

export type StoryCardProps = {
  image_name: string;
  fallback_image_name: string;
  headline: string;
  restaurant_name: string;
  story: string;
  tag?: string;
  link: string;
  className?: string;
};

export const StoryCard: React.FC<StoryCardProps> = props => {
  return (
    <div className={`${props.className}`}>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0">
          <picture>
            <source
              srcSet={getImageSrc(props.image_name)}
              type={'image/webp'}
            />
            <source srcSet={getImageSrc(props.fallback_image_name)} />
            <img
              className="lazyload h-48 w-full object-cover "
              width={'600'}
              height={'360'}
              data-src={getImageSrc(props.image_name)}
              alt=""
            />
          </picture>
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <div className="block mt-2">
              <p className="text-xl font-semibold text-gray-900">
                {props.headline}
              </p>
              <p className="mt-3 text-base text-gray-500">{props.story}</p>
            </div>
          </div>
          <div className="mt-6 flex items-center">
            <div className="">
              <div className="text-sm font-medium text-primary-600">
                <div className="hover:text-primary-600 text-primary-600">
                  {props.restaurant_name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
