import React from 'react';
import { BannerText } from './BannerText';
import { BannerVideo } from './BannerVideo';

const LandingBanner: React.FC = () => {
  return (
    <>
      <div
        className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden"
        style={{ marginLeft: '-50vw', marginRight: '-50vw' }}
      >
        <div className="mx-auto max-w-7xl lg:px-8 py-6 sm:py-12 md:py-16 lg:py-20">
          <div
            className="lg:grid lg:grid-cols-2 lg:gap-8 items-center"
            style={{ marginLeft: '50vw', marginRight: '50vw' }}
          >
            <BannerText className={'z-10'} />
            <BannerVideo className={'z-10'} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingBanner;
