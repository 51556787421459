import React from 'react';
import { FullBackground } from '../../../components/FullBackground';
import { StoryCardProps, StoryCard } from '../../../components/StoryCard';

type Props = {
  className?: string;
};
const restaurant_info: StoryCardProps[] = [
  {
    restaurant_name: 'Cassava, San Francisco',
    image_name: 'CassavaNew.webp',
    headline: 'Resiliency on Balboa St.',
    story:
      'At risk of permanent closure in 2017, the Outer Richmond community rallied behind the neighborhood staple. Today is no different, as Cassava and others are bouncing back with TableTab behind them.',
    link: '#',
    fallback_image_name: 'CassavaNew.jpg',
  },
  {
    restaurant_name: 'Safari, NYC',
    image_name: 'Safari.webp',
    headline: 'Adding Culture to Manhattan',
    story:
      'As the first & only Somali Restaurant in New York City, Safari enriches its community with an abundance of Somali food - from Hilib Ari to Malab Iyo Malawax - shown beautifully via their TableTab-powered ordering menu.',
    link: '#',
    fallback_image_name: 'Safari.jpg',
  },
  {
    restaurant_name: 'Ivy & Coney, Washington DC',
    image_name: 'IvyConeyIndoor.webp',
    headline: 'Diversifying Delivery in DC',
    story:
      'The men behind Ivy & Coney will not let the pandemic keep them down. In addition to serving guests with TableTab, they are serving other local restaurants with their own delivery company, DC ToGoGo.',
    link: '#',
    fallback_image_name: 'IvyConeyIndoor.jpg',
  },
];
const LandingMapStories: React.FC<Props> = ({ className }) => {
  return (
    <div className={`${className}`}>
      <FullBackground color={'bg-gray-100 -mb-32'} isSlanted={false}>
        <div
          className="relative max-w-7xl mx-auto py-16 lg:py-24"
          style={{ marginLeft: '50vw', marginRight: '50vw' }}
        >
          <div className="text-left">
            <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
              Table Talk
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Restaurant partners from across the country
            </p>
          </div>
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none mt-10 lg:mt-14 2xl:mt-20 ">
            {restaurant_info.map((story, i) => {
              return (
                <StoryCard
                  restaurant_name={story.restaurant_name}
                  link={story.link}
                  headline={story.headline}
                  image_name={story.image_name}
                  story={story.story}
                  key={i.toString()}
                  fallback_image_name={story.fallback_image_name}
                />
              );
            })}
          </div>
        </div>
      </FullBackground>
    </div>
  );
};

export default LandingMapStories;
