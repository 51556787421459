import React from 'react';

type Props = {
  className?: string;
};

const LandingDifferentiators: React.FC<Props> = ({ className }) => {
  return (
    <>
      <div className={`bg-gray-100 ${className}`}>
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t-2 border-gray-300" />
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-16 lg:py-24">
          <div className="max-w-3xl mx-auto text-left">
            <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
              Why TableTab?
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              This is what we bring to the table:
            </p>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            <div className="flex">
              <svg
                className="flex-shrink-0 h-6 w-6 text-primary-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
              <div className="ml-3">
                <dt className="text-lg md:text-xl leading-6 font-medium text-gray-900">
                  Support
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Super quick, hospitality-level service that lets you focus on
                  other things.
                </dd>
              </div>
            </div>

            <div className="flex">
              <svg
                className="flex-shrink-0 h-6 w-6  text-primary-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                />
              </svg>
              <div className="ml-3">
                <dt className=" text-lg md:text-xl  leading-6 font-medium text-gray-900">
                  Simplicity
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Our tabs are easy to order from for guests and easy to manage
                  for your staff.
                </dd>
              </div>
            </div>

            <div className="flex">
              <svg
                className="flex-shrink-0 h-6 w-6  text-primary-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                />
              </svg>
              <div className="ml-3">
                <dt className="text-lg md:text-xl  leading-6 font-medium text-gray-900">
                  Design
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  A{' '}
                  <a
                    id={'M5MOWO'}
                    href={
                      '/compare/pdfs?ref=home_differentiators_M5MOWO_inline'
                    }
                    className={'text-primary-600 hover:text-primary-700'}
                    onClick={() => {
                      // e.preventDefault();
                      // @ts-ignore
                      analytics.track('Compare Clicked', {
                        title: 'visual_picture_oriented_design',
                        action: 'click',
                        location: 'home_differentiators',
                        destination: 'compare_pdfs_hero',
                      });
                      // history.push('/use-cases/fsr#margin-impact?ref=home_product_aoBx9N_inline')
                    }}
                  >
                    visual, picture-oriented design
                  </a>{' '}
                  that will keep your guests
                  <a
                    id={'OtoQJj'}
                    href={
                      '/compare/pdfs?ref=home_differentiators_OtoQJj_inline'
                    }
                    className={'text-primary-600 hover:text-primary-700'}
                    onClick={() => {
                      // e.preventDefault();
                      // @ts-ignore
                      analytics.track('Compare Clicked', {
                        title: 'glued_to_the_menu',
                        action: 'click',
                        location: 'home_differentiators',
                        destination: 'compare_pdfs_hero',
                      });
                      // history.push('/use-cases/fsr#margin-impact?ref=home_product_aoBx9N_inline')
                    }}
                  >
                    {' '}
                    hungry for more
                  </a>
                  .
                </dd>
              </div>
            </div>

            <div className="flex">
              <svg
                className="flex-shrink-0 h-6 w-6  text-primary-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                />
              </svg>
              <div className="ml-3">
                <dt className="text-lg md:text-xl  leading-6 font-medium text-gray-900">
                  Reliability
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Our systems operate with 99.9%+ uptime with 100% of orders
                  processed & paid out.
                </dd>
              </div>
            </div>
          </dl>
        </div>
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t-2 w-10 border-gray-300" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingDifferentiators;
