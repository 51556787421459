import React from 'react';
import { ProductManagement } from './components/ProductManagement';
import ProductPricing from '../Product/components/ProductPricing';
import { Helmet } from 'react-helmet';
// const LandingBanner = lazy(() => import('./components/Banner'));
// const LandingProduct = lazy(() => import('./components/LandingProduct'));
// const UseCase = lazy(() => import('./components/UseCase'));
// const LandingDifferentiators = lazy(
//   () => import('./components/LandingDifferentiators'),
// );
// const LandingMapStories = lazy(() => import('./components/LandingMapStories'));
import LandingMapStories from './components/LandingMapStories';
import LandingBanner from './components/Banner';
import LandingProduct from './components/LandingProduct';
import LandingDifferentiators from './components/LandingDifferentiators';
import UseCase from './components/UseCase';

export const Landing: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TableTab | Mobile Order & Pay Software for Restaurants</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="TableTab provides restaurants with mobile order and payment software to simplify operations, increase sales and profits, protect staff, and improve the guest experience. TableTab's digital tab allows restaurants, bars, breweries, and other food concepts to run a cost-effective contactless dining solution and mitigate staffing pains."
        />
        <link rel="canonical" href="https://tabletab.io/" />
      </Helmet>
      <div className={'bg-gray-100'}>
        <LandingBanner />
        <LandingProduct className={'md:mt-20'} />
        <UseCase />
        <ProductManagement />
        <LandingDifferentiators className={'mt-20 mb-20 lg:py-20'} />
        <LandingMapStories className={'mb-32 mt-10'} />
        <ProductPricing className={'py-20 lg:py-32 xl:py-40'} />
      </div>
    </>
  );
};

export default Landing;
