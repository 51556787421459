import React, { useState } from 'react';
import { marketingAPI } from '../../../api/marketing';
import { MarketingAPIQueuePushRequest } from '../../../interfaces/marketing';

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export const BannerText: React.FC<Props> = ({ className, style }) => {
  const [inputEmail, setInputEmail] = useState('');

  return (
    <div
      className={` text-left lg:col-start-1 items-center -mt-10 xl:mt-0 ${className}`}
      style={style}
    >
      <h1 className="text-3xl tracking-tight font-bold  sm:text-3xl md:text-5xl mt-20 lg:mt-0">
        <div className={'row'}>
          {/* <span className="block  inline text-gray-900">Digital </span> */}
          <span className="block inline text-gray-100">
            Restaurants Manage an Outdated Business Model.{' '}
          </span>
          <span className="block inline text-primary-600">
            We're fixing it.
          </span>
        </div>
      </h1>
      <p className="px-0 mt-3 text-base text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        COVID-19 exposed a business model many know is out of date. Restaurants
        use TableTab's{' '}
        <span className="block inline text-primary-600">
          mobile order and payment software{' '}
        </span>
        to run a business that works for the long-term.
      </p>
      <br />
      <p className="inline-flex items-center justify-center p-4 m-1 rounded-full py-1 text-sm font-medium text-gray-800 bg-gray-100">
        Dine-In
      </p>
      <p className="inline-flex items-center justify-center p-4 m-1 rounded-full py-1 text-sm font-medium text-gray-800 bg-gray-100">
        Bars & Breweries
      </p>
      <p className="inline-flex items-center justify-center p-4 m-1 rounded-full py-1 text-sm font-medium text-gray-800 bg-gray-100">
        Counter Service
      </p>
      <p className="inline-flex items-center justify-center p-4 m-1 rounded-full py-1 text-sm font-medium text-gray-800 bg-gray-100">
        Takeout
      </p>
      <div className="mt-5 sm:mt-8 sm:flex justify-start">
        <form
          className="mt-5 sm:flex sm:items-center w-full lg:w-full"
          id={'home_hero_form'}
          onSubmit={async e => {
            e.preventDefault();
            // @ts-ignore
            window.analytics.identify({
              email: inputEmail,
            });
            const pushToQ: MarketingAPIQueuePushRequest = {
              email: inputEmail,
            };
            await marketingAPI.pushEmailToQueue(pushToQ);
            window.open('https://portal.tabletab.io/signup?ref=home_hero_form');
          }}
        >
          <div className="max-w-xs w-full ">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              required
              onChange={v => {
                setInputEmail(v.target.value);
              }}
              className="shadow-sm focus:ring-primary-600 focus:border-primary-600 block w-full text-base border-gray-300 rounded-md"
              placeholder="Enter your email"
            />
          </div>
          <button
            type="submit"
            className=" mt-3 w-full inline-flex items-center justify-center px-2 py-2 border text-base border-transparent shadow-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 sm:mt-0 sm:ml-3 sm:w-1/2 lg:w-3/5 md:w-2/3 "
          >
            Create an Account
          </button>
        </form>
      </div>
    </div>
  );
};
